<template>
  <div class="selectProducts">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="客房" name="tag_1">
        <div class="search">
          <span>客房名称:</span>
          <el-input v-model="roomListData.roomName" placeholder="请输入客房名称"></el-input>
          <span>所属酒店:</span>
          <el-select v-model="roomListData.hotelId" clearable @clear="onClear(1)" placeholder="请选择">
            <el-option v-for="item in hotelList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
            </el-option>
          </el-select>
          <el-button type="primary" @click="getRoomList()">查找</el-button>
        </div>
        <el-table size="mini" border :data="roomData" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column align="center" prop="roomId" label="客房id" width="width">
          </el-table-column>
          <el-table-column align="center" prop="roomName" label="客房名称" width="width">
          </el-table-column>
          <el-table-column align="center" prop="hotelName" label="所属酒店" width="width">
          </el-table-column>
          <el-table-column align="center" prop="strSellWay" label="销售方式" width="width">
          </el-table-column>
          <el-table-column align="center" prop="strUnsubscribe" label="是否退订" width="width">
          </el-table-column>
          <el-table-column align="center" prop="isShow" label="是否显示" width="width">
          </el-table-column>
          <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd([row], 1)">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
          :current-page="roomListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="roomListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="roomPagination.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="门票" name="tag_2">
        <div class="search">
          <span>门票名称:</span>
          <el-input v-model="ticketListData.ticketName" placeholder="请输入门票名称"></el-input>
          <span>所属景区:</span>
          <el-select v-model="ticketListData.sceneryId" clearable @clear="onClear(2)" placeholder="请选择">
            <el-option v-for="item in sceneryList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
            </el-option>
          </el-select>
          <el-button type="primary" @click="getTicketList()">查找</el-button>
        </div>
        <el-table size="mini" border :data="ticketDate" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column align="center" prop="ticketId" label="门票ID" width="width">
          </el-table-column>
          <el-table-column align="center" prop="ticketName" label="门票名称" width="width">
          </el-table-column>
          <el-table-column align="center" prop="sceneryName" label="所属景区" width="width">
          </el-table-column>
          <el-table-column align="center" prop="strSellWay" label="销售方式" width="width">
          </el-table-column>
          <el-table-column align="center" prop="isShow" label="是否显示" width="width">
          </el-table-column>
          <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd([row], 2)">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
          :current-page="ticketListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="ticketListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="ticketPagination.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="线路" name="tag_3">
        <div v-if="!taocanListData.travelId">
          <div class="search line">
            <span>商品名称:</span>
            <el-input v-model="lineListData.travelName" placeholder="请输入商品名称"></el-input>
            <span>所属分类:</span>
            <el-select clearable @clear="onClear(4)" v-model="cateValue" placeholder="请选择">
              <el-option v-for="item in cateListA" :key="item.cateId" :label="item.cateName" :value="item.cateId">
              </el-option>
            </el-select>
            <el-select clearable @clear="onClear(5)" v-show="cateValue" v-model="cateValue1" placeholder="请选择">
              <el-option v-for="item in cateListB" :key="item.cateId" :label="item.cateName" :value="item.cateId">
              </el-option>
            </el-select>
            <el-select clearable @clear="onClear(3)" v-show="cateValue1" v-model="lineListData.cateId" placeholder="请选择">
              <el-option v-for="item in cateListC" :key="item.cateId" :label="item.cateName" :value="item.cateId">
              </el-option>
            </el-select>
            <el-button type="primary" @click="getlineList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="lineDate" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="travelId" label="线路ID" width="width">
            </el-table-column>
            <el-table-column align="center" prop="travelName" label="副标题" width="width">
            </el-table-column>
            <el-table-column align="center" prop="cateName" label="所属分类" width="width">
            </el-table-column>
            <el-table-column align="center" prop="unsubscribe" label="是否退订" width="width">
            </el-table-column>
            <el-table-column align="center" prop="isShow" label="是否显示" width="width">
            </el-table-column>
            <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 3)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
            :current-page="lineListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="lineListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="linePagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="万旅卡" name="tag_4">
        <div>
          <div class="search line">
            <span>万旅卡名称:</span>
            <el-input v-model="cardList.cardName" placeholder="请输入分类名称" style="margin-right: 10px"></el-input>
            <el-button type="primary" @click="getCardList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="cateListD" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="cardId" label="卡片ID" width="width">
            </el-table-column>
            <el-table-column align="center" prop="cateName" label="所属分类" width="width">
            </el-table-column>
            <el-table-column align="center" prop="cardName" label="卡片名称" width="width">
            </el-table-column>
            <el-table-column align="center" prop="label" label="标签" width="width">
            </el-table-column>
            <el-table-column align="center" prop="price" label="价格" width="width">
            </el-table-column>
            <el-table-column align="center" prop="strStatus" label="状态" width="width">
            </el-table-column>

            <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 4)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangeMall" @current-change="handleChangeMall"
            :current-page="cardList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="cardList.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="cardList.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="精选商城" name="tag_5">
        <div>
          <div class="search line">
            <span>名称:</span>
            <el-input v-model="selectMallList.prodName" placeholder="请输入商品名称" style="margin-right: 10px"></el-input>
            <el-button type="primary" @click="getSeletMallList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="cateListF" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="prodId" label="商品ID" width="width">
            </el-table-column>
            <el-table-column align="center" prop="prodName" label="商品名称" width="width">
            </el-table-column>
            <el-table-column align="center" prop="prodPrice" label="价格" width="width">
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="prodPrice"
              label="价格"
              width="width"
            >
            </el-table-column> -->
            <el-table-column align="center" prop="prodStatus" label="状态" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 5)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangeMall" @current-change="handleChangeMall"
            :current-page="selectMallList.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="selectMallList.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="selectMallList.total">
          </el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="秒杀" name="tag_6">
        <div>
          <div class="search">
            <span>活动名称:</span>
            <el-input v-model="miaoshaListData.seckillName" placeholder="请输入活动名称"></el-input>
            <span>活动状态:</span>
            <el-select v-model="miaoshaListData.status" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>是否显示:</span>

            <el-select clearable v-model="miaoshaListData.isShow" placeholder="请选择">
              <el-option v-for="item in isShowOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="getMiaoshaList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="miaoshaData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="seckillId" label="活动ID" width="width"></el-table-column>
            <el-table-column align="center" prop="seckillName" label="活动名称" width="300"></el-table-column>
            <el-table-column align="center" prop="seckillTime" label="活动时间" width="300"></el-table-column>
            <el-table-column align="center" label="活动图片">
              <template slot-scope="{ row }">
                <el-image id="img" :src="row.imgUrl" :preview-src-list="[row.imgUrl]"></el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="strIsBack" label="是否退订" width="width"></el-table-column>
            <el-table-column align="center" prop="strChange" label="是否改期" width="width"></el-table-column>
            <el-table-column align="center" prop="label" label="标签" width="width"></el-table-column>
            <el-table-column align="center" prop="stock" label="活动数量" width="width"></el-table-column>
            <el-table-column align="center" prop="strStatus" label="活动状态" width="width"></el-table-column>
            <el-table-column align="center" prop="isShow" label="是否显示">
            </el-table-column>

            <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 6)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangeMiaosha" @current-change="handleCurrentMiaosha"
            :current-page="miaoshaListData.currentPage" :page-sizes="[5, 10, 15, 20]"
            :page-size="miaoshaListData.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="miaoshaPagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="拼团" name="tag_7">
        <div>
          <div class="search">
            <span>活动名称:</span>
            <el-input v-model="ptListData.seckillName" placeholder="请输入活动名称"></el-input>
            <span>活动状态:</span>
            <el-select v-model="ptListData.status" placeholder="请选择">
              <el-option v-for="item in optionsPt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>是否显示:</span>

            <el-select clearable v-model="ptListData.isShow" placeholder="请选择">
              <el-option v-for="item in isShowOptionsPt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="getPtList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="ptData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column width="100" align="center" prop="groupBuyId" label="活动ID"></el-table-column>
            <el-table-column align="center" prop="groupBuyName" label="活动名称" width="300"></el-table-column>
            <el-table-column width="300" align="center" prop="groupBuyTime" label="活动时间"></el-table-column>
            <el-table-column align="center" label="活动图片">
              <template slot-scope="{ row }">
                <el-image id="img" :src="row.imgUrl" :preview-src-list="[row.imgUrl]"></el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" prop="groupBuyPeopleCount" label="成团人数">
            </el-table-column>
            <el-table-column align="center" prop="label" label="标签"></el-table-column>
            <el-table-column align="center" prop="stock" label="活动数量" width="100"></el-table-column>

            <el-table-column align="center" prop="strStatus" label="活动状态"></el-table-column>
            <el-table-column align="center" prop="isShow" label="是否显示">
            </el-table-column>

            <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 7)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangePt" @current-change="handleCurrentPt"
            :current-page="ptListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="ptListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="ptPagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="美食" name="tag_8">
        <div>
          <div class="search">
            <span>ID:</span>
            <el-input v-model="foodListData.foodId" placeholder="请输入美食ID"></el-input>
            <span>名称:</span>
            <el-input v-model="foodListData.title" placeholder="请输入活动名称"></el-input>
            <span>分类:</span>

            <el-select v-model="foodFatherId" @change="getFartherCate(foodFatherId, 2)" placeholder="请选择">
              <el-option v-for="item in foodCateList" :key="item.cateId" :label="item.cateName"
                :value="item.cateId"></el-option>
            </el-select>
            <el-select v-if="foodFatherId" v-model="foodListData.cateId" placeholder="请选择">
              <el-option v-for="item in foodCateListSun" :key="item.cateId" :label="item.cateName"
                :value="item.cateId"></el-option>
            </el-select>
            <el-button type="primary" @click="getFoodList">查找</el-button>
          </div>
          <el-table size="mini" border :data="foodData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column width="100" align="center" prop="foodId" label="美食ID"></el-table-column>
            <el-table-column align="center" prop="cateName" label="所属分类" width="300"></el-table-column>
            <el-table-column align="center" prop="title" label="美食名称"></el-table-column>

            <el-table-column align="center" label="美食图片">
              <template slot-scope="{ row }">
                <el-image id="img" :src="row.showImg" :preview-src-list="[row.showImg]"></el-image>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="label" label="标签"></el-table-column>

            <el-table-column align="center" prop="wlShow" label="万旅网显示" width="width">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 8)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangeFood" @current-change="handleCurrentFood"
            :current-page="foodListData.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="foodListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="foodPagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="预售" name="tag_9">
        <div>
          <div class="search">
            <span>预售名称:</span>
            <el-input v-model="presaleListData.presaleName" placeholder="请输入活动名称"></el-input>
            <span>状态:</span>
            <el-select v-model="presaleListData.status" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>是否显示:</span>

            <el-select clearable v-model="presaleListData.isShow" placeholder="请选择">
              <el-option v-for="item in isShowOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="getPresaleList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="presaleData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="presaleId" label="预售ID" width="width"></el-table-column>
            <el-table-column align="center" prop="presaleName" label="预售名称" width="300"></el-table-column>
            <el-table-column align="center" prop="presaleTime" label="预售时间" width="300"></el-table-column>
            <el-table-column align="center" label="预售图片">
              <template slot-scope="{ row }">
                <el-image id="img" :src="row.imgUrl" :preview-src-list="[row.imgUrl]"></el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="strIsBack" label="是否退订" width="width"></el-table-column>
            <el-table-column align="center" prop="strChange" label="是否改期" width="width"></el-table-column>
            <el-table-column align="center" prop="label" label="标签" width="width"></el-table-column>
            <el-table-column align="center" prop="stock" label="预售数量" width="width"></el-table-column>
            <el-table-column align="center" prop="strStatus" label="预售状态" width="width"></el-table-column>
            <el-table-column align="center" prop="isShow" label="是否显示">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 9)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangePresale" @current-change="handleCurrentPresale"
            :current-page="presaleListData.currentPage" :page-sizes="[5, 10, 15, 20]"
            :page-size="presaleListData.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="presalePagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="超值礼包" name="tag_10">
        <div>
          <div class="search">
            <span>超值礼包名称:</span>
            <el-input v-model="giftBagListData.title" placeholder="请输入活动名称"></el-input>
            <span>所属分类:</span>

            <el-select @clear="giftBagListData.cateId = ''" clearable v-model="giftBagListData.cateId" placeholder="请选择">
              <el-option v-for="item in giftBagCateList" :key="item.cateId" :label="item.cateName" :value="item.cateId">
              </el-option>
            </el-select>
            <span>状态:</span>
            <el-select v-model="giftBagListData.status" placeholder="请选择">
              <el-option v-for="item in giftBagStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>是否显示:</span>

            <el-select clearable v-model="giftBagListData.isShow" placeholder="请选择">
              <el-option v-for="item in giftBagIsShowOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button type="primary" @click="getGiftBagList()">查找</el-button>
          </div>
          <el-table size="mini" border :data="giftBagData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column align="center" prop="packId" label="ID" width="width"></el-table-column>
            <el-table-column align="center" prop="title" label="标题" width="300"></el-table-column>
            <el-table-column align="center" prop="cateName" label="所属分类" width="300"></el-table-column>
            <el-table-column align="center" label="展示图">
              <template slot-scope="{ row }">
                <el-image id="img" :src="row.showImg" :preview-src-list="[row.showImg]"></el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="label" label="标签" width="width"></el-table-column>
            <el-table-column align="center" label="活动时间" width="width">
              <template slot-scope="{ row }">
                <span>{{ row.beginTime + "-" + row.endTime }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="label" label="标签" width="width"></el-table-column>
            <el-table-column align="center" prop="stock" label="库存" width="width"></el-table-column>
            <el-table-column align="center" prop="strStatus" label="状态" width="width"></el-table-column>
            <el-table-column align="center" prop="isShow" label="是否显示">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 10)">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleChangegiftBag" @current-change="handleCurrentgiftBag"
            :current-page="giftBagPagination.currentPage" :page-sizes="[5, 10, 15, 20]"
            :page-size="giftBagPagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="giftBagPagination.total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="pb_footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="onAdd(multipleSelection, activeName)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  checkRoomList,
  checkTicketList,
  checkTravelList,
  checkMiaoshalList,
  checkPresaleList,
  checkPtList,
  foodList,
} from "@/api/wanxiaoShop";
import { allHotel } from "@/api/hotelList";

import { allScenery } from "@/api/sceneryList";

import { selectAllByFatherId } from "@/api/travel";
import {

  foodCateAllById,

} from "../../api/food.js";
import { travelSpecsList } from "@/api/travelSpecs";
import { cardListWXD } from "@/api/wanlvCard";
import { selectMallProfitList } from "@/api/selectMall";
import { getGiftPackageListWXD, getClassifyList } from "@/api/seckillInfo";
export default {
  name: "productBox",
  data() {
    return {
      cardList: {
        pageSize: 5,
        currentPage: 1,
        cardName: "",
      },
      selectMallList: {
        pageSize: 5,
        currentPage: 1,
        prodName: "",
      },
      hotelList: [],
      roomData: [],
      roomListData: {
        currentPage: 1,
        pageSize: 5,
        hotelId: null,
        roomName: "",
        status: 1,
        activityType: 1,
      },
      roomPagination: {},
      sceneryList: [],
      ticketDate: [],
      ticketListData: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
        status: 1,
        activityType: 1,
      },
      ticketPagination: {},
      lineDate: [],
      lineListData: {
        currentPage: 1,
        pageSize: 5,
        travelName: "",
        cateId: null,
        status: 1,
        activityType: 1,
      },
      linePagination: {},
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      cateListD: [],
      cateListF: [],
      taocanPagination: {},
      taocanDate: [],
      taocanListData: {
        currentPage: 1,
        pageSize: 5,
        specsName: "",
        travelId: null,
        status: 1,
      },
      miaoshaPagination: {},
      miaoshaDate: [],
      miaoshaListData: {
        currentPage: 1,
        pageSize: 5,
        seckillName: "",
        status: 0,
        isShow: -2,
      },
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "进行中" },
        { value: 2, label: "未开始" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已失效" },
      ],
      ptPagination: {},
      ptData: [],
      ptListData: {
        currentPage: 1,
        pageSize: 5,
        groupBuyName: "",
        status: 0,
        isShow: -2,
      },
      optionsPt: [
        { value: 0, label: "全部" },
        { value: 1, label: "进行中" },
        { value: 2, label: "未开始" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已失效" },
      ],
      isShowOptionsPt: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      foodData: [],
      foodListData: {
        currentPage: 1,
        pageSize: 5,
        activityType: 1,
        title: '',
        cateId: '',
        foodId: ''
      },
      foodFatherId:'',
      foodPagination: {},
      foodCateList: [],
      foodCateListSun: [],
      presaleData: [],
      presaleListData: {
        currentPage: 1,
        pageSize: 5,
        presaleName: "",
        status: 0,
        isShow: -2,
        // activityType: 1,
      },
      presalePagination: {},
      giftBagCateList: "",
      giftBagListData: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        status: null,
        cateId: null,
        isShow: 1,
      },
      giftBagData: {},
      giftBagIsShowOptions: [
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      giftBagStatus: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已开始",
        },
        {
          value: 3,
          label: "已结束",
        },
        {
          value: 4,
          label: "已失效",
        },
      ],
      giftBagPagination: {},
      activeName: "tag_1",
      multipleSelection: [], // 多选项
    };
  },
  watch: {
    cateValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.cateListB = data.data;
    },
    cateValue1: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.cateListC = data.data;
    },
  },
  created() {
    this.getAllHotel();
    this.getRoomList();
    this.getallScenery();
    this.getTicketList();
    this.getTravelCate();
    this.getlineList();
    this.getCardlist();
    this.getSeletMallList();
    this.getMiaoshaList();
    this.getPtList();
    this.getFoodList();
    this.getPresaleList();
    this.getGiftBagList();
    this.getGiftBagCate();
    this.getFartherCate();
  },
  methods: {
    getFartherCate(fatherId = 0, type = 1) {
      this.foodListData.cateId = ''
      this.foodCateListSun = []
      foodCateAllById({ fatherId }).then((res) => {
        if (res.data.code == 0) {
          if (type == 2) {

            this.foodCateListSun = res.data.data;
          } else {
            this.foodCateList = res.data.data;
          }

          //   this.fatherId = res.data.data[0].cateId;
        }
      });
    },
    async getGiftBagList() {
      const { data } = await getGiftPackageListWXD(this.giftBagListData);
      this.giftBagData = data.list;
      this.giftBagPagination = data.pagination;
    },
    async getGiftBagCate() {
      const { data } = await getClassifyList();
      this.giftBagCateList = data.data;
    },
    onClear(value) {
      if (value == 1) {
        this.roomListData.hotelId = null;
      } else if (value == 2) {
        this.ticketListData.sceneryId = null;
      } else if (value == 3) {
        this.lineListData.cateId = null;
      } else if (value == 4) {
        this.cateValue = null;
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      } else if (value == 5) {
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.activeName, this.multipleSelection);
    },
    // 查询所有酒店
    async getAllHotel() {
      const { data } = await allHotel();
      this.hotelList = data.data;
    },
    async getRoomList() {
      const { data } = await checkRoomList(this.roomListData);
      this.roomData = data.list;
      this.roomPagination = data.pagination;
    },
    //查询所有景区
    async getallScenery() {
      const { data } = await allScenery();
      this.sceneryList = data.data;
    },
    async getTicketList() {
      const { data } = await checkTicketList(this.ticketListData);
      console.log(data, "qeqw1321");
      this.ticketDate = data.list;
      this.ticketPagination = data.pagination;
    },
    async getMiaoshaList() {
      const { data } = await checkMiaoshalList(this.miaoshaListData);
      console.log(data, "qeqw1321");
      this.miaoshaData = data.list;
      this.miaoshaPagination = data.pagination;
    },
    async getPtList() {
      const { data } = await checkPtList(this.ptListData);
      console.log(data, "qeqw1321");
      this.ptData = data.list;
      this.ptPagination = data.pagination;
    },
    async getFoodList() {
      const { data } = await foodList(this.foodListData);
      console.log(data, "qeqw1321");
      this.foodData = data.list;
      this.foodPagination = data.pagination;
    },
    async getPresaleList() {
      const { data } = await checkPresaleList(this.presaleListData);
      console.log(data, "qeqw1321");
      this.presaleData = data.list;
      this.presalePagination = data.pagination;
    },
    async getTravelCate() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.cateListA = data.data;
    },
    async getlineList() {
      const { data } = await checkTravelList(this.lineListData);
      this.lineDate = data.list;
      this.linePagination = data.pagination;
    },
    //万旅卡
    async getCardlist() {
      const { data } = await cardListWXD(this.cardList);
      if (data.code === 0) {
        this.cateListD = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    //精选商城列表
    async getSeletMallList() {
      const { data } = await selectMallProfitList(this.selectMallList);
      console.log(data, "精选商城列表");
      if (data.code === 0) {
        this.cateListF = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    async gettravelSpecsList() {
      const { data } = await travelSpecsList(this.taocanListData);
      console.log(data, "13213adaw");
      this.taocanDate = data.list;
      this.taocanPagination = data.pagination;
    },
    async onAdd(list, strType) {
      console.log(strType, list);
      let type = this.activeName.split("_")[1];
      if (list.length < 1) {
        return this.$message.error("请先选择产品！");
      }
      if (type == 1) {
        var newList = list.map((item) => {
          return {
            id: item.roomId,
            name: item.roomName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 2) {
        var newList = list.map((item) => {
          return {
            id: item.ticketId,
            name: item.ticketName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 3) {
        var newList = list.map((item) => {
          return {
            id: item.travelId,
            name: item.travelName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 4) {
        var newList = list.map((item) => {
          return {
            id: item.cardId,
            name: item.cardName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 5) {
        var newList = list.map((item) => {
          return {
            id: item.prodId,
            name: item.prodName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 6) {
        var newList = list.map((item) => {
          return {
            id: item.seckillId,
            name: item.seckillName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 7) {
        var newList = list.map((item) => {
          return {
            id: item.groupBuyId,
            name: item.groupBuyName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 8) {
        var newList = list.map((item) => {
          return {
            id: item.foodId,
            name: item.title,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 9) {
        var newList = list.map((item) => {
          return {
            id: item.presaleId,
            name: item.presaleName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 10) {
        var newList = list.map((item) => {
          return {
            id: item.packId,
            name: item.title,
          };
        });
        this.$emit("addData", newList, type);
      }
    },
    onTaocan(id) {
      this.taocanListData.travelId = id;
      this.gettravelSpecsList();
    },
    handleSizeChange1(num) {
      this.roomListData.pageSize = num;
      this.getRoomList();
    },
    handleChangeCard(num) {
      this.cardList.pageSize = num;
      this.getCardlist();
    },
    handleCurrentCard(num) {
      this.cardList.pageSize = num;
      this.getCardlist();
    },
    handleChangeMall(num) {
      this.selectMallList.pageSize = num;
      this.getSeletMallList();
    },
    handleCurrentMall(num) {
      this.selectMallList.pageSize = num;
      this.getSeletMallList();
    },
    handleChangeMiaosha(num) {
      this.miaoshaListData.pageSize = num;
      this.getMiaoshaList();
    },
    handleCurrentMiaosha(num) {
      this.miaoshaListData.currentPage = num;
      this.getMiaoshaList();
    },
    handleChangePt(num) {
      this.ptListData.pageSize = num;
      this.getPtList();
    },
    handleCurrentPt(num) {
      this.ptListData.currentPage = num;
      this.getPtList();
    },
    handleChangeFood(num) {
      this.foodListData.pageSize = num;
      this.getFoodList();
    },
    handleCurrentFood(num) {
      this.foodListData.currentPage = num;
      this.getFoodList();
    },
    handleChangePresale(num) {
      this.presaleListData.pageSize = num;
      this.getPresaleList();
    },
    handleCurrentPresale(num) {
      this.presaleListData.currentPage = num;
      this.getPresaleList();
    },
    handleChangegiftBag(num) {
      this.giftBagListData.pageSize = num;
      this.getGiftBagList();
    },
    handleCurrentgiftBag(num) {
      this.giftBagListData.currentPage = num;
      this.getGiftBagList();
    },

    handleCurrentChange1(num) {
      this.roomListData.currentPage = num;
      this.getRoomList();
    },
    handleSizeChange2(num) {
      this.ticketListData.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange2(num) {
      this.ticketListData.currentPage = num;
      this.getTicketList();
    },
    handleSizeChange3(num) {
      this.lineListData.pageSize = num;
      this.getlineList();
    },
    handleCurrentChange3(num) {
      this.lineListData.currentPage = num;
      this.getlineList();
    },
    handleSizeChange4(num) {
      this.taocanListData.pageSize = num;
      this.gettravelSpecsList();
    },
    handleCurrentChange4(num) {
      this.taocanListData.currentPage = num;
      this.gettravelSpecsList();
    },
  },
};
</script>
<style lang="less" scoped>
.selectProducts {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .search {
    span {
      margin: 0 20px;
    }

    .el-input {
      width: 200px;
    }

    .el-select {
      margin: 0 20px;
    }
  }

  .line {
    .el-select {
      width: 150px;
    }
  }

  .el-table {
    margin-top: 20px;
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }

  .pb_footer {
    margin-top: 30px;
    display: flex;
    align-items: center;

    // justify-content: flex-start;
    .el-button {
      margin-right: 20px;
    }
  }
}
</style>
